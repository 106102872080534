<footer id="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="copyright"> Copyright &copy; 2022 <strong>Padahweb</strong>. All Rights Reserved. Designed & Developed by <a href="https://vecan.co/" style='color:#fff;text-decoration: underline;'>Vecan</a> </div>
            </div>
        </div>
    </div>
</footer><!-- End  Footer -->

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-chevron-up"></i></a>