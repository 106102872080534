export const url = Object.freeze({
    // imgurl : 'https://padahweb.vecan.co/',
    // apiurl : 'https://api.padahweb.com/', 
    // SOCKET_ENDPOINT : 'https://api.padahweb.com:3001',

    imgurl : 'http://localhost:4200/',
    apiurl : 'http://localhost:3000/',
    SOCKET_ENDPOINT : 'http://localhost:1434'
    

})
