<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div class="backdrop" [ngStyle]="{'display':eventmodaldisplay}"></div>

<section id='home-slider'>
    <div class="product-screens">
        <video autoplay="" muted="" playsinline="" loop="" id="home_banner" oncanplay="this.play()"
            onloadedmetadata="this.muted = true">
            <source src="../../../assets/img/pdh/padahweb.mp4" type="video/mp4">
        </video>
    </div>
</section>

<main id="main">
  <div id="features1"> </div> <br/>
  <div id="features" class="features section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="features-content">
            <div class="row">
              <div class="col-lg-3">
                <div class="features-item first-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
                  <div class="first-number number">
                    <h6>01</h6>
                  </div>
                  <div class="icon"></div>
                  <h4>Mobile Learning</h4>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="features-item second-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                  <div class="second-number number">
                    <h6>02</h6>
                  </div>
                  <div class="icon"></div>
                  <h4>Personalized Calendar</h4>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="features-item first-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                  <div class="third-number number">
                    <h6>03</h6>
                  </div>
                  <div class="icon"></div>
                  <h4>Attendance Tracking</h4>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="features-item second-feature last-features-item wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                  <div class="fourth-number number">
                    <h6>04</h6>
                  </div>
                  <div class="icon"></div>
                  <h4>Roles & Access Management</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="about1"> </div> <br/>
  <div id="about" class="about-us section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="left-image wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
            <img src="assets/img/pdh/about-left-image.png" alt="">
          </div>
        </div>
        <div class="col-lg-6 align-self-center wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
          <div class="section-heading">
            <h6>About Us</h6>
            <h2>Making <em>Learning</em> fun &amp; <span>rewarding</span></h2>
          </div>
          <p>Padahweb is a Learning Management System with Immaculate UI, Engaging & Self-paced Courses, Compact Calendar, Attendance tracking, Teacher-Students Interaction and Management of roles</p>
        </div>
      </div>
    </div>
  </div>

  <div id="services1"> </div>
  <div id="services" class="our-services section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="section-heading wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">
            <h6>Our Advantage</h6>
            <h2>Discover What We Do &amp; <span>Offer</span> To Our <em>Clients</em></h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.3s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon">
                  <img src="assets/img/pdh/service-icon-01.png" alt="">
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Relevant & Engaging content</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.4s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon">
                  <img src="assets/img/pdh/service-icon-02.png" alt="">
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Positive learning and teaching experience</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon">
                  <img src="assets/img/pdh/service-icon-03.png" alt="">
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Convenient learning at time & any place</h4>                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.6s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon">
                  <img src="assets/img/pdh/service-icon-03.png" alt="">
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Learning through your computer or mobile device</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.7s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon">
                  <img src="assets/img/pdh/service-icon-01.png" alt="">
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Well-defined process assisting administrator </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.8s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon">
                  <img src="assets/img/pdh/service-icon-02.png" alt="">
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Defining the right learning objective for all the courses</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="contact1"> </div>
  <div id="contact" class="contact-us section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
          <form id="contact" action="" method="post">
            <div class="row">
              <div class="col-lg-6 offset-lg-3">
                <div class="section-heading">
                  <h6>Contact Us</h6>
                  <h2>Fill Out The Form Below and We Will Get in Touch with You</h2>
                </div>
              </div>
              <div class="col-lg-12">
                <form role="form" #f="ngForm" class='row' (ngSubmit)="f.form.valid && visitorDetails(f)">
                  <div class="col-lg-6">
                    <fieldset>
                      <input type="text" name="visitor_name" id="visitor_name" placeholder="Your Name*" [(ngModel)]="visitorsdetails.visitor_name" #visitor_name="ngModel" [ngClass]="{'invalid-data': visitor_name.invalid && f.submitted, 'valid-data': visitor_name.valid && f.submitted}" required>
                      <div class="error_msg"
                      *ngIf="visitor_name.errors && (visitor_name.dirty || visitor_name.touched || f.submitted)">
                        <div *ngIf="visitor_name.errors.required">
                          <small class="error_text" >Name is required</small> 
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div class="col-lg-6">
                    <fieldset>
                      <input type="text" name="visitor_subject" placeholder='Subject' id='visitor_subject' [(ngModel)]="visitorsdetails.visitor_subject" #visitor_subject="ngModel" [ngClass]="{'invalid-data': visitor_subject.invalid && f.submitted, 'valid-data': visitor_subject.valid && f.submitted}" required>
                      <div class="error_msg" *ngIf="visitor_subject.errors && (visitor_subject.dirty || visitor_subject.touched || f.submitted)">
                        <div *ngIf="visitor_subject.errors.required">
                          <small class="error_text" >Subject is required</small> 
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div class="col-lg-6">
                    <fieldset>
                      <input type="email" name="visitor_email_id" id="visitor_email_id"  placeholder="Your Email*" data-rule="email" data-msg="Please enter a valid email" [(ngModel)]="visitorsdetails.visitor_email_id" #visitor_email_id="ngModel" [pattern]="emailPattern" [ngClass]="{'invalid-data':visitor_email_id.invalid && f.submitted, 'valid-data':visitor_email_id.valid && f.submitted}" required />
                      <div class="error_msg" *ngIf="visitor_email_id.errors && (visitor_email_id.dirty || visitor_email_id.touched || f.submitted)">
                        <div *ngIf="visitor_email_id.errors.required">
                          <small class="error_text" >Email Id is required</small> 
                        </div>
                        <div *ngIf="visitor_email_id.errors.pattern">
                          <small class="error_text" > Email Id is invalid</small> 
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div class="col-lg-6">
                    <fieldset>
                      <input type="text" name="visitor_contact_number" id="visitor_contact_number" placeholder="Your Phone*" data-rule="minlen:10" data-msg="Phone number must have 10 chars" [(ngModel)]="visitorsdetails.visitor_contact_number" [ngClass]="{'invalid-data': visitor_contact_number.invalid && f.submitted, 'valid-data': visitor_contact_number.valid && f.submitted}" #visitor_contact_number="ngModel"  (focusout)="numberValidate($event)" required />
                      <div class="error_msg" *ngIf="visitor_contact_number.errors && (visitor_contact_number.dirty || visitor_contact_number.touched || f.submitted)">
                        <div *ngIf="visitor_contact_number.errors.pattern">
                            <small class="error_text" >Only Numbers allowed</small> 
                        </div>
                        <div *ngIf="visitor_contact_number.errors.required">
                            <small class="error_text" >Phone Number is required</small> 
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div class="col-lg-12">
                    <fieldset>
                      <textarea name="message" rows="5" id='message' placeholder="Message*" [(ngModel)]="visitorsdetails.message" [ngClass]="{'invalid-data': message.invalid && f.submitted, 'valid-data': message.valid && f.submitted}" #message="ngModel" required></textarea>
                      <div class="error_msg" *ngIf="message.errors && (message.dirty || message.touched || f.submitted)">
                        <div *ngIf="message.errors.required">
                          <small class="error_text" >Message is required</small> 
                        </div>
                      </div> 
                    </fieldset>
                  </div>

                  <div class="col-lg-12">
                    <fieldset>
                      <button type="submit" id="form-submit" class="main-button ">Send Message Now</button>
                    </fieldset>
                  </div>
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>