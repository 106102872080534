<div class="card">
    <div class="modal-title text-center">
        <img src="../../../assets/img/pdh/logo.png" alt="" style='margin-bottom:20px;'>
    </div>
    <div class="card-body">
        <form [formGroup]="form" id='resetForm' (ngSubmit)="resetPassword()">
            <div class="form-group">
                <label for="reset_email_id">Email ID</label>
                <input type="text" id='reset_email_id' class="form-control" disabled />
            </div>
            <div class="form-group">
                <label for="reset_pass_word">Password</label>
                <div class="input-group">
                    <input type="password" id='reset_pass_word' [type]="hide ? 'password' : 'text'" formControlName="pass_word" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pass_word.errors }" />
                    <div class="input-group-addon">
                        <i class="fa" [ngClass]="{'fa-eye-slash': !hide , 'fa-eye': hide }"   aria-hidden="true" (click)="toggleFieldTextType()"></i>
                    </div>
                </div>
                <div *ngIf="submitted && f.pass_word.errors" class="invalid-feedback">
                    <div *ngIf="f.pass_word.errors.required">Password is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="confirmpassword">Confirm Password</label>
                <div class="input-group">
                    <input type="password" id='confirmpassword' [type]="hideconfirm ? 'password' : 'text'"  formControlName="confirmpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" />
                    <div class="input-group-addon">
                        <i class="fa" [ngClass]="{'fa-eye-slash': !hideconfirm , 'fa-eye': hideconfirm }"   aria-hidden="true" (click)="toggleFieldTextTypeConfirm()"></i>
                    </div>
                </div>
                <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmpassword.errors.required">Confirm Password is required</div>
                </div>
            </div>
            <div class="form-group m-0">
                <button [disabled]="loading" class="btn btn-primary mt-3 w-100">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Reset Your Password
                </button>
            </div>
        </form>
    </div>
</div>